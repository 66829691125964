<template>
  <div class="main-wrap">
    <!-- 导航栏 -->
    <!-- 查询表单 -->
    <el-form :inline="true" :model="queryForm">
      <el-form-item>
        <el-input
          v-model="queryForm.wechatNumber"
          placeholder="请输入微信号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="queryData"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <div class="aciton-box">
      <el-button type="primary" @click="showDialog">添加微信号</el-button>
    </div>
    <el-table
      ref="tableData"
      v-loading="loading"
      border
      stripe
      :data="tableData"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      height="1000"
    >
      <el-table-column
        type="index"
        label="序号"
        width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        label="分组名称"
        min-width="120"
        align="center"
        prop="groupName"
      >
      </el-table-column>
      <el-table-column
        prop="wechatNumber"
        label="微信号"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="status"
        label="上架状态"
        min-width="120"
        align="center"
      >
        <template slot-scope="scope">
          <el-switch
            :value="!!!scope.row.status"
            @change="checkStatus(scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="conversionAmount"
        label="今日复制"
        min-width="120"
        align="center"
      >
        <template slot-scope="scope">
          <div>
            {{ scope.row.conversionAmount ? scope.row.conversionAmount : 0 }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="120" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleDetail(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="text"
            style="margin: 0 10px"
            @click="handleCopy(scope.row)"
            >复制</el-button
          >
          <el-button
            slot="reference"
            size="mini"
            type="text"
            @click="handleDelete(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page.sync="queryForm.pageNo"
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size.sync="queryForm.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="queryData"
      @current-change="queryData"
      @prev-click="queryData"
      @next-click="queryData"
    >
    </el-pagination>
    <el-dialog
      :title="isEdit ? '编辑微信号' : '添加微信号'"
      :visible.sync="wechatDialog"
      :close-on-click-modal="false"
      width="35%"
      :before-close="clearForm"
    >
      <div class="wechat-form">
        <el-form
          ref="wechatForm"
          :model="wechatForm"
          :rules="wechatRules"
          label-width="80px"
        >
          <el-form-item prop="groupId" label="分组名称">
            <el-select v-model="wechatForm.groupId" style="width: 220px">
              <el-option
                v-for="(item, index) in wechatGroupMap"
                :key="index"
                :label="item.groupName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="wechatNumber" label="微信号">
            <el-input
              v-model="wechatForm.wechatNumber"
              style="width: 220px"
              maxlength="32"
            ></el-input>
          </el-form-item>
          <el-form-item prop="status" label="上架状态">
            <el-switch v-model="wechatForm.status"></el-switch>
          </el-form-item>
          <el-form-item prop="remark" label="备注">
            <el-input
              v-model="wechatForm.remark"
              style="width: 220px"
              type="textarea"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div class="action-sumbit">
              <el-button @click="clearForm">取消</el-button>
              <el-button type="primary" @click="addWechat">确定</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryForm: {
        wechatNumber: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      tableData: [],
      loading: false,
      wechatDialog: false,
      wechatForm: {
        groupId: null,
        remark: "",
        status: true,
        wechatNumber: "",
      },
      wechatRules: {
        groupId: [{ required: true, message: "请选择分组", trigger: "blur" }],
        remark: [{ message: "请输入备注", trigger: "blur" }],
        status: [{ required: true, message: "请选择状态", trigger: "blur" }],
        wechatNumber: [
          { required: true, message: "请输入微信号", trigger: "blur" },
        ],
      },
      wechatGroupMap: [],
      isEdit: false,
    };
  },
  mounted() {
    this.queryData();
    this.getWechatGroup();
  },
  methods: {
    queryData() {
      this.loading = true;
      this.$http
        .get("/system/weChat/getPage", { params: this.queryForm })
        .then((response) => {
          if (response.data.code === 200) {
            this.tableData = response.data.data;
            this.total = response.data.count;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkStatus(e) {
      this.$confirm("是否确认修改状态？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          id: e.id,
          status: e.status ? 0 : 1,
        };
        this.$http
          .post("/system/weChat/editStatus", params)
          .then((response) => {
            if (response.data.code === 200) {
              this.$message.success("修改成功！");
              this.queryData();
            }
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    showDialog() {
      this.wechatDialog = true;
    },
    getWechatGroup() {
      this.$http.get("/system/landing/getWeChatGroupList").then((response) => {
        if (response.data.code === 200) {
          this.wechatGroupMap = response.data.data;
        }
      });
    },
    addWechat() {
      this.$refs.wechatForm.validate((valid) => {
        if (valid) {
          let params = this.wechatForm;
          params.status = params.status ? 0 : 1;
          let url = this.isEdit ? "/system/weChat/edit" : "/system/weChat/add";
          this.$http.post(url, params).then((response) => {
            if (response.data.code === 200) {
              this.$message({
                message: this.isEdit ? "修改成功！" : "添加成功！",
                type: "success",
                duration: 3000,
              });
              this.queryData();
              this.clearForm();
            }
          });
        }
      });
    },
    handleDetail(e) {
      this.isEdit = true;
      let params = {
        id: e.id,
      };
      this.$http
        .get("/system/weChat/getDetail", { params })
        .then((response) => {
          if (response.data.code === 200) {
            Object.assign(this.wechatForm, response.data.data);
            this.wechatForm.status = this.wechatForm.status == 0 ? true : false;
          }
        });
      this.wechatDialog = true;
    },
    handleDelete(e) {
      this.$confirm("是否删除这条信息？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http
          .delete("/system/weChat/delete?id=" + e.id)
          .then((response) => {
            if (response.data.code === 200) {
              this.$message({
                message: "删除成功",
                type: "success",
                duration: 2000,
              });
              this.queryData();
            }
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    handleCopy(e) {
      let params = {
        id: e.id,
      };
      this.$http
        .get("/system/weChat/getDetail", { params })
        .then((response) => {
          if (response.data.code === 200) {
            Object.assign(this.wechatForm, response.data.data);
          }
        });
      this.wechatDialog = true;
    },
    clearForm() {
      this.wechatDialog = false;
      this.isEdit = false;
      this.$refs.wechatForm.resetFields();
    },
  },
};
</script>

<style scoped>
.aciton-box {
  padding-bottom: 20px;
}
.wechat-form /deep/ .el-form-item__label {
  width: 80px;
}
.action-sumbit {
  /* text-align: center; */
}
</style>
